import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { AddField, GoUp, Save } from '../../assets/icons';
import { checkVal, getLang, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { ISWOT, ISWOTItem } from '../../interfaces/snp.interface';
import { FormBlockButton } from '../../components/insfrastructureProjects/infrastructure.styles';
import { updateScreening } from '../../requests/screening.request';
import { OBLAST_KATO } from '../../constants/snp.constant';

interface IProps {
  data: ISWOT;
  updateForm: () => void;
}

export const swotOrder = ['strengths', 'weaknesses', 'opportunities', 'threats']

const SWOTPage: FC<IProps> = ({ data, updateForm }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [swotData, setSwotData] = useState<ISWOT>();
  const [isAdding, setIsAdding] = useState<keyof ISWOT | null>(null);
  const [newItem, setNewItem] = useState<ISWOTItem>({
    textKz: '',
    textRu: ''
  });

  const validateForm = (data: any) => {
    if (!swotData) return;
    setErrors({});
    let res = true;

    const swotAnalysis = Object.keys(data);
    swotAnalysis.forEach((key: any) => {
      if (data[key]) {
        data[key].forEach((item: any, index: number) => {
          if (!checkVal(item)) {
            setErrors((prev: any) => ({ ...prev, [`swotData.${key}.${index}`]: true }));
            res = false;
          }
        })
      }
    })

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    if (kato && validateForm(swotData)) {
      updateScreening(+kato, 'swotAnalysis', { ...swotData })
        .then(() => {
          updateForm();
          toast.success(t('toast.save_success'))
        })
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/screening/SWOT`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}/screening/SWOT`)
  }

  const handleSaveNewItem = (type: keyof ISWOT) => {
    setErrors({});

    if (!newItem.textRu || !newItem.textKz) {
      setErrors((prev: any) => ({ ...prev, [`newItem.text`]: true }));
      toast.error('Заполните поле на двух языках.');
      return;
    }

    setSwotData((prev: any) => ({
      ...prev,
      [type]: prev[type] ? [...prev[type]!, newItem] : [newItem],
    } as ISWOT));

    setNewItem({ textRu: '', textKz: '' });
    setIsAdding(null);
  };


  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderCategory = (type: keyof ISWOT, lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return (swotData
      && swotData[type]
      && <FormBlock type='white'>
        <div className="title bold">{t('screening-page.swot-analysis.' + type, lang)}</div>
        {
          swotData[type]?.map((item, index) => (
            <FormGroup>
              <Field
                name={`swotData.${type}.${index}.text${lang}`}
                className={`${errors[`swotData.${type}.${index}`] ? 'error' : ''}`}
                type={'text'}
                as="input"
                value={item[`text${lang}`]}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value.length < 160 && value.split(' ').length < 31) {
                    setSwotData((prev: any) => ({
                      ...prev,
                      [type]: prev[type].map((el: any, i: number) => i === index ? {
                        ...el,
                        [`text${lang}`]: e.target.value
                      } : el)
                    }))
                    setFieldValue(`swotData.${type}.${index}.text${lang}`, e.target.value)
                  }
                }}
              />
            </FormGroup>
          ))
        }

        {
          isAdding === type && <FormGroup>
            <Field
              name={`newItem.text${lang}`}
              className={`${errors[`newItem.text`] ? 'error' : ''}`}
              type={'text'}
              as="input"
              value={newItem[`text${lang}`]}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value.length < 160 && value.split(' ').length < 31) {
                  setNewItem((prev: any) => ({ ...prev, [`text${lang}`]: e.target.value }))
                }
              }}
            />
          </FormGroup>
        }

        {
          isAdding === type
            ? <>
              <FormBlockButton onClick={() => handleSaveNewItem(type)}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(null)}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(type)}>
              <AddField className='blue' /> {t('form.add_field', lang)}
            </FormBlockButton>
        }
      </FormBlock>)
      || <></>
  };

  useEffect(() => {
    if (data) {
      setSwotData(data);
    }
  }, [data]);


  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        swotData && <>
          {
            <FormBlock type='white'>
              <div className="title bold">{t('screening-page.swot-analysis.title')} </div>
              {
                swotOrder.map((key: any) => (
                  renderCategory(key, lang, setFieldValue)
                ))
              }
            </FormBlock>
          }
        </>
      }
    </div>
  }

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default SWOTPage