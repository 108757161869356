import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { ArrowDown, DarkArrowRight, GoUp, Save } from '../../assets/icons';
import { checkVal, findIndexByKey, getLang, getSelfSufficiencyPercentage, getYearVal, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { IProductSufficiencyItem } from '../../interfaces/snp.interface';
import { updateFormPartial } from '../../requests/supervisor.request';
import { OBLAST_KATO, years } from '../../constants/snp.constant';


const SelfSufficiencyPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);

  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [openIds, setOpenIds] = useState<{ [id: string]: boolean }>({});

  const [basicStats, setBasicStats] = useState<any>(null);
  const [productSelfSufficiencyStats, setProductSelfSufficiencyStats] = useState<IProductSufficiencyItem[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [status, setStatus] = useState<'COMPLETED' | 'IN_PROGRESS'>();

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    const foodData = data.basicStats.filter((item: any) => item.type === 'FOOD')
    if (foodData.some((item: any) => checkVal(item.currentYearVal) || checkVal(item.previousYearVal))) {
      for (const item of foodData) {
        if (!checkVal(item.currentYearVal) || !checkVal(item.previousYearVal)) {
          setErrors((prev: any) => ({ ...prev, [`FOOD-${item.subType}`]: true }))
          toast.error(t(`errors.FOOD-${item.subType}`, language));
          return false;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      basicStats,
      productSelfSufficiencyStats,
      status
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);

    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/self-sufficiency`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setBasicStats(res.basicStats);
      setProductSelfSufficiencyStats(res.productSelfSufficiencyStats);
      setIsLoading(false);
      setStatus(res.status);

      const temp = res.productSelfSufficiencyStats.reduce((acc: any, item: any) => {
        acc[item.productNameRu] = openIds[item.productNameRu] || false;
        return acc;
      }, {})

      setOpenIds(temp);
    });


  }, [region, snp, kato])

  const areAllOpen = (obj: any) => {
    return Object.keys(obj).every((key) => obj[key]);
  }

  const handleExpandAll = (val: boolean) => {
    const temp = Object.keys(openIds).reduce((acc: any, key: string) => {
      acc[key] = val;
      return acc;
    }, {});

    setOpenIds(temp);
  };

  const handleOpenId = (id: string) => {
    setOpenIds((prev: any) => ({ ...prev, [id]: !prev[id] }))
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      <FormBlock type='white'>
        <div className="title bold">{t(`new-fields.FOOD`, lang)}</div>

        {
          basicStats.filter((item: any) => item.type === 'FOOD').map((stat: any) =>
            <FormBlock type='white'>
              <div className="title bold">
                {t(`new-fields.${stat.subType}`, lang)}
              </div>

              {years.map((year) => (
                <FormGroup key={year}>
                  <label
                    htmlFor={`basicStats[${findIndexByKey(`FOOD-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                    className="required"
                  >
                    {t(`new-fields.${stat.type}-${stat.subType}`, lang).replace('&type', `${year === 2023 ? 'план' : 'факт'}`).replace('&year', year.toString())}
                  </label>

                  <Field
                    name={`basicStats[${findIndexByKey(`FOOD-${stat.subType}`, basicStats)}].${getYearVal(year)}`}
                    type="number"
                    as="input"
                    value={basicStats[findIndexByKey(`FOOD-${stat.subType}`, basicStats)]?.[getYearVal(year)] ?? ''}
                    onChange={(e: any) => {
                      const val = e.target.value;
                      if (val === '') {
                        setFieldValue(`basicStats[${findIndexByKey(`FOOD-${stat.subType}`, basicStats)}].${getYearVal(year)}`, '');
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`FOOD-${stat.subType}`, basicStats) ? {
                            ...item,
                            [getYearVal(year)]: ''
                          } : item)
                        )
                      } else {
                        setFieldValue(`basicStats[${findIndexByKey(`FOOD-${stat.subType}`, basicStats)}].${getYearVal(year)}`, Math.max(0, val).toString());
                        setBasicStats(
                          basicStats.map((item: any, i: number) => i === findIndexByKey(`FOOD-${stat.subType}`, basicStats) ? {
                            ...item,
                            [getYearVal(year)]: Math.max(0, val).toString()
                          } : item)
                        )
                      }
                    }}
                    className={`${errors[`${stat.type}-${stat.subType}`]
                      ? 'error'
                      : ''
                      }`}
                  />
                </FormGroup>
              ))}
            </FormBlock>
          )
        }
      </FormBlock>

      {
        productSelfSufficiencyStats && productSelfSufficiencyStats.length > 0
        && <FormBlock type="white">
          <div className="title bold with-chevron">
            {t('productSelfSufficiencyStats.title')}
            <span>{areAllOpen(openIds) ? <ArrowDown onClick={() => handleExpandAll(false)} /> : <DarkArrowRight onClick={() => handleExpandAll(true)} />}</span>
          </div>
          {productSelfSufficiencyStats.map((item: IProductSufficiencyItem, index: number) => (
            <FormBlock type='white' style={{ marginBottom: 0 }}>
              <div className="title bold with-chevron">
                {item[`productName${lang}`]}
                {openIds[item.productNameRu] ? <ArrowDown onClick={() => handleOpenId(item.productNameRu)} /> : <DarkArrowRight onClick={() => handleOpenId(item.productNameRu)} />}
              </div>
              {
                openIds[item.productNameRu]
                && <Fragment key={item[`productName${lang}`]}>
                  {
                    ['currentYearVal', 'previousYearVal', 'annualConsumptionPerPerson', 'annualConsumption', 'annualProductProduction'].map((key) => (
                      <FormGroup>
                        <label
                          htmlFor={`productSelfSufficiencyStats[${index}].${key}`}
                          className="required"
                        >
                          {t(`productSelfSufficiencyStats.${key}`, lang)}
                        </label>

                        <Field
                          name={`productSelfSufficiencyStats[${index}].${key}`}
                          type="number"
                          as="input"
                          onChange={(e: any) => {
                            const val = e.target.value;
                            setFieldValue(`productSelfSufficiencyStats[${index}].${key}`, val);
                            setProductSelfSufficiencyStats(
                              productSelfSufficiencyStats.map((item: any, i: number) => i === index ? {
                                ...item,
                                [key]: val
                              } : item)
                            )
                          }}
                          value={item[key as keyof IProductSufficiencyItem] ?? ''}

                        />
                      </FormGroup>
                    ))
                  }
                  <FormGroup>
                    <label
                      htmlFor={`productSelfSufficiencyStats[${index}].selfSufficiencyPercentage`}
                      className="required"
                    >
                      {t(`productSelfSufficiencyStats.selfSufficiencyPercentage`, lang)}
                    </label>

                    <Field
                      name={`productSelfSufficiencyStats[${index}].selfSufficiencyPercentage`}
                      type="number"
                      as="input"
                      disabled
                      value={getSelfSufficiencyPercentage(item)}
                    />
                  </FormGroup>
                </Fragment>}
            </FormBlock>
          ))}

        </FormBlock>
      }

    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default SelfSufficiencyPage