import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { AddField, GoUp, Save } from '../../assets/icons';
import { checkVal, getLang, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { IMCB, IScreeningMCBCreditSupport, IScreeningMCBSubjects, IScreeningRegisteredSubjects } from '../../interfaces/snp.interface';
import { updateScreening } from '../../requests/screening.request';
import { OBLAST_KATO } from '../../constants/snp.constant';
import { text } from 'stream/consumers';
import { FormBlockButton } from '../../components/insfrastructureProjects/infrastructure.styles';

interface IProps {
  data: IMCB;
  updateForm: () => void;
}

interface INewObj {
  labelKz: string;
  labelRu: string;
  value: string;
}

const defaultObj: INewObj = {
  labelKz: '',
  labelRu: '',
  value: ''
}

export const creditSupportKeys = ['subsidizing', 'guarantee', 'grant',]

const MCBPage: FC<IProps> = ({ data, updateForm }) => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [mcbData, setMcbData] = useState<IMCB>();

  const [isAdding, setIsAdding] = useState(false);
  const [newObj, setNewObj] = useState<any>(defaultObj);

  const validateForm = (data: any) => {
    if (!mcbData) return;
    setErrors({});
    let res = true;

    const mcbSubjects = mcbData.mcbSubjects || {} as IScreeningMCBSubjects;

    if (Object.values(mcbSubjects).some((item) => !checkVal(item)) && Object.values(mcbSubjects).some((item) => checkVal(item))) {
      for (const key in mcbSubjects) {
        if (!key.includes('total') && !checkVal(mcbSubjects[key as keyof IScreeningMCBSubjects])) {
          setErrors((prev: any) => ({ ...prev, [`mcbSubjects.${key}`]: true }))
          toast.error(t('errors.mcbSubjects', language));
          return;
        }
      }
    }

    const registeredSubjects = mcbData.registeredSubjects || {} as IScreeningRegisteredSubjects;
    if (Object.values(registeredSubjects).some((item) => !checkVal(item)) && Object.values(registeredSubjects).some((item) => checkVal(item))) {
      for (const key in registeredSubjects) {
        if (!key.includes('total') && !checkVal(registeredSubjects[key as keyof IScreeningRegisteredSubjects])) {
          setErrors((prev: any) => ({ ...prev, [`registeredSubjects.${key}`]: true }))
          toast.error(t('errors.registeredSubjects', language));
          return;
        }
      }
    }

    const creditSupport = mcbData.creditSupport || {} as IScreeningMCBCreditSupport;
    if (Object.values(creditSupport).some((item) => !checkVal(item)) && Object.values(creditSupport).some((item) => checkVal(item))) {
      for (const key in creditSupport) {
        if (!key.includes('total') && !checkVal(creditSupport[key as keyof IScreeningMCBCreditSupport])) {
          setErrors((prev: any) => ({ ...prev, [`creditSupport.${key}`]: true }))
          toast.error(t('errors.creditSupport', language));
          return;
        }
      }
    }

    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    if (kato && validateForm(mcbData)) {
      updateScreening(+kato, 'smbDevelopment', { ...mcbData })
        .then(() => {
          updateForm();
          toast.success(t('toast.save_success'))
        })
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/screening/MCB`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}/screening/MCB`)
  }

  const handleAddObj = () => {
    if (Object.values(newObj).every((item) => checkVal(item))) {
      mcbData && setMcbData({
        ...mcbData,
        registeredSubjects: {
          ...mcbData.registeredSubjects,
          [`created-${+new Date()}`]: newObj,
        } as IScreeningRegisteredSubjects
      });
      setNewObj(defaultObj);
      setIsAdding(false);
    } else {
      toast.error(t('errors.streetName', language));
      setErrors((prev: any) => ({ ...prev, [`newObj.label`]: true }))
    }
  }

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        mcbData && <>
          <FormBlock type='white'>
            <div className="title bold">{t('screening-page.mcb-subjects.title', lang)}</div>
            {
              mcbData.mcbSubjects && Object.keys(mcbData.mcbSubjects).map((key, index) => (
                <FormGroup key={index}>
                  <label
                    htmlFor={`mcbData.mcbSubjects.${key}`}
                    className="required"
                  >{t('screening-page.mcb-subjects.' + key, lang)}
                  </label>
                  <Field
                    name={`mcbData.mcbSubjects.${key}`}
                    className={`${errors[`mcbSubjects.${key}`] ? 'error' : ''}`}
                    type='number'
                    min={0}
                    step={'0.0001'}
                    as="input"
                    onChange={(e: any) => {
                      const val = e.target.value || '';
                      setMcbData({
                        ...mcbData,
                        mcbSubjects: {
                          ...mcbData.mcbSubjects,
                          [key]: val
                        }
                      } as IMCB);
                      setFieldValue(`mcbData.mcbSubjects.${key}`, +val);
                    }}
                    value={mcbData?.mcbSubjects?.[key as keyof IScreeningMCBSubjects] || ''}
                  />
                </FormGroup>
              ))
            }
          </FormBlock>

          <FormBlock type='white'>
            <div className="title bold">{t('screening-page.registered-subjects.title', lang)}</div>
            {
              mcbData.registeredSubjects && Object.keys(mcbData.registeredSubjects).map((key, index) => (
                !key.includes('created')
                  ? <FormGroup key={index}>
                    <label
                      htmlFor={`mcbData.registeredSubjects.${key}`}
                      className="required"
                    >{t('screening-page.registered-subjects.' + key, lang)}
                    </label>
                    <Field
                      name={`mcbData.registeredSubjects.${key}`}
                      className={`${errors[`registeredSubjects.${key}`] ? 'error' : ''}`}
                      type='number'
                      min={0}
                      step={'0.0001'}
                      as="input"
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setMcbData({
                          ...mcbData,
                          registeredSubjects: {
                            ...mcbData.registeredSubjects,
                            [key]: val
                          }
                        } as IMCB);
                        setFieldValue(`mcbData.registeredSubjects.${key}`, +val);
                      }}
                      value={mcbData?.registeredSubjects?.[key as keyof IScreeningRegisteredSubjects] || ''}
                    />
                  </FormGroup>
                  : <FormGroup key={index}>
                    <label
                      htmlFor={`mcbData.registeredSubjects.${key}.value`}
                      className="required"
                    >{mcbData?.registeredSubjects?.[key][`label${lang}`] || ''}
                    </label>
                    <Field
                      name={`mcbData.registeredSubjects.${key}.value`}
                      className={`${errors[`registeredSubjects.${key}`] ? 'error' : ''}`}
                      type='number'
                      min={0}
                      step={'0.0001'}
                      as="input"
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setMcbData({
                          ...mcbData,
                          registeredSubjects: {
                            ...mcbData.registeredSubjects,
                            [key]: {
                              ...mcbData?.registeredSubjects?.[key],
                              value: val
                            }
                          }
                        } as IMCB);
                        setFieldValue(`mcbData.registeredSubjects.${key}.value`, +val);
                      }}
                      value={mcbData?.registeredSubjects?.[key as keyof IScreeningRegisteredSubjects].value || ''}
                    />
                  </FormGroup>

              ))
            }
            {
              isAdding &&
              <>
                <FormGroup>
                  <div className="building">
                    <Field
                      name={`newObj.label${lang}`}
                      className={`${errors[`newObj.label`] ? 'error' : ''}`}
                      type='text'
                      as="input"
                      onChange={(e: any) => {
                        setNewObj({
                          ...newObj,
                          [`label${lang}`]: e.target.value
                        });
                      }}
                      value={newObj[`label${lang}`] ?? ''}
                    />
                    <Field
                      name={`newObj.value`}
                      className={`${errors[`newObj.value`] ? 'error' : ''}`}
                      type='text'
                      as="input"
                      step={'0.0001'}
                      onChange={(e: any) => {
                        const val = e.target.value || '';
                        setNewObj({
                          ...newObj,
                          value: val
                        });
                      }}
                      value={newObj.value ?? ''}
                    />
                  </div>
                </FormGroup>
              </>
            }
          </FormBlock>


          {
            isAdding
              ? <>
                <FormBlockButton onClick={handleAddObj}>{t('save', lang)}</FormBlockButton>
                <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(false)}>{t('cancel', lang)}</FormBlockButton>
              </>
              : <FormBlockButton style={{ marginBottom: 10 }} onClick={() => setIsAdding(true)}>
                <AddField className='blue' /> Сфера деятельности МСБ
                 {/* {t('form.add_field', lang)} */}
              </FormBlockButton>
          }

          <FormBlock type='white'>
            <FormGroup >
              <label
                htmlFor={`mcbData.totalSum`}
                className="required"
              >{t('screening-page.totalSum', lang)}
              </label>
              <Field
                name={`mcbData.mcbSubjects.totalSum`}
                className={`${errors[`mcbSubjects.totalSum`] ? 'error' : ''}`}
                type='number'
                min={0}
                step={'0.0001'}
                as="input"
                onChange={(e: any) => {
                  const val = e.target.value || '';
                  setMcbData({
                    ...mcbData,
                    totalSum: val
                  } as IMCB);
                  setFieldValue(`mcbData.mcbSubjects.totalSum`, val);
                }}
                value={mcbData?.totalSum || ''}
              />
            </FormGroup>
          </FormBlock>

          <FormBlock type='white'>
            <div className="title bold">{t('screening-page.credit-support.title', lang)}</div>
            {
              creditSupportKeys.map((key) => (
                <FormBlock type='white'>
                  <div className="title bold">{t('screening-page.credit-support.' + key, lang)}</div>
                  {
                    ['projects', 'sum'].map((item, index) => (
                      <FormGroup key={index}>
                        <label
                          htmlFor={`mcbData.creditSupport.${key}_${item}`}
                          className="required"
                        >{t(`screening-page.credit-support.${key}_${item}`, lang)}
                        </label>
                        <Field
                          name={`mcbData.creditSupport.${key}_${item}`}
                          className={`${errors[`creditSupport.${key}_${item}`] ? 'error' : ''}`}
                          type='number'
                          min={0}
                          step={'0.0001'}
                          as="input"
                          onChange={(e: any) => {
                            const val = e.target.value || '';
                            setMcbData({
                              ...mcbData,
                              creditSupport: {
                                ...mcbData.creditSupport,
                                [`${key}_${item}`]: val
                              }
                            } as IMCB);
                            setFieldValue(`mcbData.creditSupport.${key}_${item}`, val);
                          }}
                          value={mcbData?.creditSupport?.[`${key}_${item}` as keyof IScreeningMCBCreditSupport] || ''}
                        />
                      </FormGroup>
                    ))
                  }

                </FormBlock>
              ))
            }
          </FormBlock>
        </>
      }
    </div >
  }

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  useEffect(() => {
    if (data) {
      setMcbData(data);
    }
  }, [data])

  return (
    <div style={{ position: 'relative' }}>
      {
        <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default MCBPage