/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer, FooterButton, FormBlock, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';
import { Form, Formik, Field } from 'formik';
import { GoUp, Save } from '../../assets/icons';
import { checkVal, getLang, getTaxValue, handleGoUp, isRegion, t, updateKatoAccessList } from '../../utils/helpers.utils';
import { toast, ToastContainer } from 'react-toastify';
import { getGeneralInfo } from '../../requests/snp.request';
import { updateFormPartial } from '../../requests/supervisor.request';
import { ITaxStat } from '../../interfaces/snp.interface';
import { OBLAST_KATO } from '../../constants/snp.constant';

const TaxPage = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [tree, setTree] = useState<any[]>([]);

  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [katoAccessList, setKatoAccessList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isKatoRegion, setIsKatoRegion] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [taxStats, setTaxStats] = useState<ITaxStat[]>([]);
  const [status, setStatus] = useState<'COMPLETED' | 'IN_PROGRESS'>();

  const validateForm = (data: any) => {
    setErrors({});
    let res = true;
    if (kato && taxStats) {
      for (const item of taxStats) {
        const taxStatsKey = Object.keys(item).filter((key) => !['id', 'fact', 'plan', 'annual', 'annualPercent', 'planPercent', 'fixedAssetsSaleAnnualPercent', 'fixedAssetsSalePlanPercent', 'nonTaxRevenuesAnnualPercent', 'nonTaxRevenuesPlanPercent', 'taxRevenuesAnnualPercent', 'taxRevenuesPlanPercent'].includes(key));
        if (taxStatsKey.some((key) => key !== 'type' && checkVal(item[key as keyof ITaxStat]))) {
          for (const key of taxStatsKey) {
            if (!checkVal(item[key as keyof ITaxStat])) {
              setErrors((prev: any) => ({ ...prev, [`taxStats.${key}`]: true }))
              toast.error(t(`errors.streetName`, language));
              res = false;
              return;
            }
          }
        }
      }
    }
    return res;
  }

  const handleSubmitForm = (values: any) => {
    setErrors({});

    const data = {
      taxStats,
      status
    }

    if (kato && validateForm(data)) {
      updateFormPartial(kato, data)
        .then(() => {
          toast.success(t('toast.save_success'))
          loadForm();
        })
        .catch(() => toast.error('Ошибка при сохранении'));
    }
  }

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);

    kato && isRegion(+kato) && navigate(`/admin/${e.target.value}/tax`)
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res: any) => {
      setTaxStats(res.taxStats)
      setStatus(res.status);
      setIsLoading(false);
    });

  }, [region, snp, kato])

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      kato && +kato !== OBLAST_KATO && <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {tree.map((item) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>

        {!isKatoRegion && <FormGroup>
          <label htmlFor="snp">{t(`form.district.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {tree.find(item => +item.kato === +region)?.children.map((item: any) => <option key={item.kato} value={item.kato}>{item[`name${getLang()}`]}</option>)}
          </Field>
        </FormGroup>}
      </div>
    )
  }

  const getType = (type: string) => {
    if (kato) {
      if (type === 'REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'DISTRICT_BUDGET';
        } else if (!isRegion(+kato)) {
          return 'SO_BUDGET';
        } else {
          return 'REGIONAL_BUDGET';
        }
      } else if (type === 'PROPER_REGIONAL_BUDGET') {
        if (isRegion(+kato) && +kato !== OBLAST_KATO) {
          return 'PROPER_DISTRICT_BUDGET';
        } else {
          return 'PROPER_REGIONAL_BUDGET';
        }
      }
    }
  }

  const renderFields = (lang: 'Ru' | 'Kz', setFieldValue: any) => {
    return <div className="grid-item">
      {
        kato && taxStats && taxStats.length !== 0 && <FormBlock type='white'>
          <div className="title bold">{t(`taxStats.title`, lang)}, {kato && isRegion(+kato) ? 'млн. ' : 'тыс. '}тенге</div>
          {
            taxStats?.map((item: ITaxStat) => {
              const shouldRenderFormBlock = !(item.type === 'PROPER_REGIONAL_BUDGET' && !isRegion(+kato));

              return shouldRenderFormBlock && <FormBlock type='white'>
                <div className="title bold">{t(`taxStats.` + getType(item.type), lang)}</div>
                {/* FACT */}
                {
                  (['fact', 'taxRevenuesFact', 'nonTaxRevenuesFact', 'fixedAssetsSaleFact'] as (keyof ITaxStat)[]).map((key: keyof ITaxStat) => (
                    <FormGroup>
                      <label htmlFor={`taxStats.${key}`}>
                        {t(`taxStats.${key}`, lang)}
                      </label>
                      <Field
                        name={`taxStats.${key}`}
                        type="number"
                        as="input"
                        className={`${errors[`taxStats.${key}`] ? 'error' : ''}`}
                        disabled={key === 'fact'}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          setFieldValue(`taxStats.${key}`, val);
                          setTaxStats((prev: ITaxStat[]) =>
                            prev.map((elem) => {
                              if (elem.type === item.type) {
                                return {
                                  ...elem,
                                  [key]: val
                                }
                              }
                              return elem;
                            })
                          )
                        }}
                        value={key === 'fact' ? getTaxValue(item, 'fact') : item[key] ?? ''}
                      />
                    </FormGroup>
                  ))
                }

                {
                  (['annual', 'taxRevenuesAnnual', 'nonTaxRevenuesAnnual', 'fixedAssetsSaleAnnual'] as (keyof ITaxStat)[]).map((key: keyof ITaxStat) => (
                    <FormGroup>
                      <label htmlFor={`taxStats.${key}`}>
                        {t(`taxStats.${key}`, lang)}
                      </label>
                      <Field
                        name={`taxStats.${key}`}
                        type="number"
                        as="input"
                        className={`${errors[`taxStats.${key}`] ? 'error' : ''}`}
                        disabled={key === 'annual'}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          setFieldValue(`taxStats.${key}`, val);
                          setTaxStats((prev: ITaxStat[]) =>
                            prev.map((elem) => {
                              if (elem.type === item.type) {
                                return {
                                  ...elem,
                                  [key]: val
                                }
                              }
                              return elem;
                            })
                          )
                        }}
                        value={key === 'annual' ? getTaxValue(item, 'annual') : item[key] ?? ''}
                      />
                    </FormGroup>
                  ))
                }

                {
                  (['plan', 'taxRevenuesPlan', 'nonTaxRevenuesPlan', 'fixedAssetsSalePlan'] as (keyof ITaxStat)[]).map((key: keyof ITaxStat) => (
                    <FormGroup>
                      <label htmlFor={`taxStats.${key}`}>
                        {t(`taxStats.${key}`, lang)}
                      </label>
                      <Field
                        name={`taxStats.${key}`}
                        type="number"
                        as="input"
                        className={`${errors[`taxStats.${key}`] ? 'error' : ''}`}
                        disabled={key === 'plan'}
                        onChange={(e: any) => {
                          const val = e.target.value;
                          setFieldValue(`taxStats.${key}`, val);
                          setTaxStats((prev: ITaxStat[]) =>
                            prev.map((elem) => {
                              if (elem.type === item.type) {
                                return {
                                  ...elem,
                                  [key]: val
                                }
                              }
                              return elem;
                            })
                          )
                        }}
                        value={key === 'plan' ? getTaxValue(item, 'plan') : item[key] ?? ''}
                      />
                    </FormGroup>
                  ))
                }

                {
                  (['planPercent', 'taxRevenuesPlanPercent', 'nonTaxRevenuesPlanPercent', 'fixedAssetsSalePlanPercent',
                    'annualPercent', 'taxRevenuesAnnualPercent', 'nonTaxRevenuesAnnualPercent', 'fixedAssetsSaleAnnualPercent'] as (keyof ITaxStat)[]).map((key: keyof ITaxStat) => (
                      <FormGroup>
                        <label htmlFor={`taxStats.${key}`}>
                          {t(`taxStats.${key}`, lang)}
                        </label>
                        <Field
                          name={`taxStats.${key}`}
                          type="number"
                          as="input"
                          disabled
                          value={getTaxValue(item, key) ?? ''}
                        />
                      </FormGroup>
                    ))
                }

              </FormBlock>
            })
          }
        </FormBlock>
      }
    </div>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  useEffect(() => {
    kato && updateKatoAccessList(katoAccessList, kato, navigate, setTree, setRegion, setSnp);
  }, [katoAccessList, kato]);

  useEffect(() => {
    if (kato) {
      setIsKatoRegion(isRegion(+kato))
    }
  }, [kato])

  useEffect(() => {
    const item = localStorage.getItem('user');
    if (item) {
      const snpInfo = JSON.parse(item);
      if (snpInfo && snpInfo.kato_access) {
        setKatoAccessList(snpInfo.kato_access)
      }
    }
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={{}}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton variant="save"><Save /> {t('save', language)}</FooterButton>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="go-up" type='button' onClick={() => handleGoUp(wrapperRef)}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default TaxPage